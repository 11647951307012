import React, { useEffect } from "react";
import book from "../../Photos/Bookfront.jpg"
const ScholarshipTestSyllabus = () => {
    useEffect(() => {
      window.scrollTo({top: 0})
    
  
    }, [])
    
  return (
    <div className="bg-slate-50 p-4 rounded-md">
      <div className="  mx-auto  max-w-3xl  p-4 bg-white  drop-shadow-md">
        <h2 className="text-2xl font-semibold mb-4 text-orange-500 bg-slate-50 px-2 rounded-md  w-full  py-2 border-gray-800">
          Scholarship Test Syllabus
        </h2>
        <table className="min-w-full bg-white border border-gray-200 rounded-lg">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-left text-gray-600">
                Subject
              </th>
              <th className="py-2 px-4 border-b text-left text-gray-600">
                Details
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">Sanatan Sanskriti</td>
              <td className="py-2 px-4 border-b">
                (Sanatan Sanskriti Gyan Prashnottari + Sanatan Dharma) (40%)
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Computer</td>
              <td className="py-2 px-4 border-b">
                6th to 10th Class Based (20%)
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Maths</td>
              <td className="py-2 px-4 border-b">
                6th to 10th Class Based (20%)
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4">GK</td>
              <td className="py-2 px-4">
                6th to 10th Class Based + Current Affairs (20%)
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <header className="font-bold text-lg text-green-500 bg-gray-50 p-2 my-2 rounded-md">Optional</header>
          <div className="flex flex-col md:flex-row md:space-y-0 space-y-4 justify-between items-center">
            <div className=" text-start ">
              <h1 className="text-blue-600 font-semibold space-x-2 text-lg uppercase">Sanatan Sanskriti Gyan Prashnottari</h1>

              <h2 className="bg-yellow-200 text-red-500  font-semibold ">Price 30 Rs.</h2>
              <h2 className="text-red-500 font-semibold my-2">Contact on Balaji Computers</h2>
              <p>
                Opposite Man Singh Tent House <br /> Dehtora Shastripuram Agra
              </p>
            </div>
            <div>
              <img alt="sanatan" width="200" height="200" src={book} />
            </div>
          </div>
        </div>
        <div className="container mx-auto p-4">
      <h1 className="text-3xl mb-4 text-center font-semibold text-red-500 bg-green-50 rounded-md p-2">Detailed Syllabus of Sanatan Sanskriti Vikas Trust</h1>
      <h2 className="text-2xl font-semibold mb-2 text-center">2nd Scholarship Exam</h2>
      
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <th className="py-3 px-4 text-left">Subject</th>
            <th className="py-3 px-4 text-left">Syllabus</th>
            <th className="py-3 px-4 text-left">Marks</th>
          </tr>
        </thead>
        <tbody className="text-gray-700 text-sm">
          <tr className="border-b border-gray-200 hover:bg-gray-100">
            <td className="py-3 px-4 text-left">Sanatan Dharm</td>
            <td className="py-3 px-4 text-left">
              <ul className="list-disc list-inside">
                <li>What is Sanatana Dharma? Why is it called Hinduism?</li>
                <li>Knowledge of the Vedas: Ṛigveda, Yajurveda, Sāmaveda, and Atharvaveda</li>
                <li>Knowledge of Itihāsa texts: The Rāmāyaṇa & the Mahābhārata</li>
                <li>Path of Ramji During Vanvas</li>
              </ul>
            </td>
            <td className="py-3 px-4 text-left">20</td>
          </tr>
          <tr className="border-b border-gray-200 hover:bg-gray-100">
            <td className="py-3 px-4 text-left">Computer</td>
            <td className="py-3 px-4 text-left">
              <ul className="list-disc list-inside">
                <li>Introduction of Computer</li>
                <li>Uses of Computer</li>
                <li>Characteristics of Computer</li>
                <li>Types of Computer</li>
                <li>Components of Computer</li>
                <li>Types of Memory</li>
                <li>Functions of CPU</li>
                <li>Types of Storage Devices</li>
                <li>Units in Computer</li>
                <li>Generations of Computer</li>
              </ul>
            </td>
            <td className="py-3 px-4 text-left">20</td>
          </tr>
          <tr className="border-b border-gray-200 hover:bg-gray-100">
            <td className="py-3 px-4 text-left">Math</td>
            <td className="py-3 px-4 text-left">
              <ul className="list-disc list-inside">
                <li>Number System</li>
                <li>Average</li>
                <li>Percentage</li>
                <li>Profit & Loss</li>
                <li>Square & Square Root</li>
              </ul>
            </td>
            <td className="py-3 px-4 text-left">20</td>
          </tr>
          <tr className="border-b border-gray-200 hover:bg-gray-100">
            <td className="py-3 px-4 text-left">GK</td>
            <td className="py-3 px-4 text-left">
              <ul className="list-disc list-inside">
                <li>Indus Valley Civilization</li>
                <li>Geography of India (Rivers, Mountains, Desert)</li>
                <li>Statistics of Indian Economy</li>
                <li>Basic Knowledge of Indian Constitution</li>
                <li>Basic Science</li>
                <li>Current Affairs</li>
              </ul>
            </td>
            <td className="py-3 px-4 text-left">20</td>
          </tr>
          <tr className="border-b border-gray-200 hover:bg-gray-100">
            <td className="py-3 px-4 text-left">Sanatan Sanskriti Gyan Prashnottari (Book)</td>
            <td className="py-3 px-4 text-left">-</td>
            <td className="py-3 px-4 text-left">20</td>
          </tr>
        </tbody>
      </table>
    </div>
      </div>
      
    </div>
  );
};

export default ScholarshipTestSyllabus;
