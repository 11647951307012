import React from 'react'
import { FaExclamationTriangle } from "react-icons/fa";
const Hinditandc = () => {
  return (
    <div><div>
    <h2 className="text-2xl font-bold text-center mb-4">निर्देश एवं शर्तें </h2>
      <div className="flex items-center text-red-600 font-bold mb-4">
        <FaExclamationTriangle className="mr-2" />
        <span>
          नोट: आवेदन करने से पहले कृपया सभी निर्देशों एवं शर्तों को भली भाँति पढ़लें।
        </span>
      </div>

      <ul className="list-disc pl-6 space-y-2">
        <li>
          अपनी सारी सूचनाएँ सही-सही भरें। सूचना गलत या मिथ्या पाये जाने पर आवेदन के साथ-साथ
          पुरस्कार के लिए दावेदारी भी निरस्त कर दी जाएगी।
        </li>
        <li>
          परीक्षा फॉर्म केवल अंग्रेजी में ही भरा जाएगा।
        </li>
        <li>
          एक आधार नंबर से केवल एक ही पंजीकरण किया जा सकता है। सभी पंजीकृत छात्रों को कंप्यूटर
          कोर्स की फीस में 10% तक की छूट दी जाएगी।
        </li>
        <li>
          परीक्षा का आयोजन ऑनलाइन होगा जिसे कंप्यूटर, मोबाइल अथवा टैबलेट पर दिया जा सकता है। 
          परीक्षा का दिनांक और समय आपको ट्रस्ट की वेबसाइट पर दिया जाएगा। 
          परीक्षा का लिंक आपको अपना रजिस्ट्रेशन नंबर और पासवर्ड डालकर आपकी प्रोफाइल पर दिया जाएगा।
        </li>
        <li>
          परीक्षा का पाठ्यक्रम निम्न प्रकार से निर्धारित होगा:
          40% सनातन धर्म आधारित प्रश्न, 20% सामान्य गणित, 20% सामान्य ज्ञान, और 20% कंप्यूटर ज्ञान।
        </li>
        <li>
          परीक्षा बहुविकल्पीय प्रश्नों पर आधारित होगी। 
          परीक्षा में 100 प्रश्नों को हल करने के लिए 90 मिनट का समय दिया जाएगा।
        </li>
        <li>
          परीक्षा का आयोजन न्यूनतम 300 पंजीकरण होने पर ही किया जाएगा, अन्यथा 50% आवेदन शुल्क
          वापस कर दिया जाएगा।
        </li>
        <li>
          परीक्षा का आयोजन सनातन संस्कृति विकास ट्रस्ट द्वारा किया जाएगा।
        </li>
        <li>
          परीक्षा में उत्तीर्ण छात्रों को कंप्यूटर कोर्स के लिए बालाजी इंस्टीट्यूट ऑफ कंप्यूटर एजुकेशन 
          दहतोरा, शास्त्रीपुरम, सिकंदरा, आगरा में ही प्रवेश दिया जाएगा।
        </li>
        <li>
          प्रथम, द्वितीय व तृतीय स्थान पर दो या दो से अधिक परीक्षार्थी समान अंक प्राप्त करते हैं, तो 
          उन्हीं के बीच लकी ड्रॉ का आयोजन किया जाएगा। 
        </li>
        <li>
          परीक्षा में सही उत्तर का एक अंक दिया जाएगा, जबकि गलत उत्तर देने पर 1/4 अंक काट लिया जाएगा।
        </li>
        <li>
          अनुचित साधन या किसी अन्य से परीक्षा में सहायता लेते हुए पाए जाने पर आपका आवेदन निरस्त कर दिया जाएगा।
        </li>
        <li>
          यदि प्रश्नपत्र में कोई प्रश्न त्रुटिपूर्ण पाया जाता है, तो उस प्रश्न के अंक सभी परीक्षार्थियों को समान दिए जाएंगे।
        </li>
        <li>
          रजिस्ट्रेशन तथा परीक्षा संबंधी किसी भी परिवर्तन या निरस्तीकरण के सभी अधिकार सनातन संस्कृति विकास ट्रस्ट के पास सुरक्षित हैं।
        </li>
        <li>
          परीक्षा का आयोजन हिन्दी और इंग्लिश में किया जाएगा।
        </li>
        <li>
          परीक्षा संबंधी किसी भी समस्या के लिए, आप 8532938381 पर कॉल कर सकते हैं।
        </li>
      </ul>
    </div></div>
  )
}

export default Hinditandc