import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import "./forms.css"
import Loading from "../../Photos/loading.gif"
const ForgetReg = ({back}) => {
  const [isloding,setIsloading] =useState(false);
    const [fatherName, setFathername] = useState("");
    const [aadhar, setAadhar] = useState("");
    const [mobno, setMobno] = useState("");
    const [mailid, setMailid] = useState("");
    const [regno, setRegno] =  useState(null)
    const [login, setlogin] = useState(true);
    const [error, setError] = useState(false);
    const [duperror,setDuperror]=useState(false);
    const [message, setMessage] = useState("Credentials are Wrong");
    const [saverr, setSaveErr] = useState(false);
    const navigate = useNavigate();
    const checkregistration = async ()=>{
        setIsloading(true)
        if(aadhar.length>0 && mobno.length>0 && mailid.length>0 && fatherName.length>0){
        try {
            const newmailid = encodeURIComponent(mailid)
            const newfathername = encodeURIComponent(fatherName)
            const response = await fetch(
                `https://sanatansvtnew-c5ol.vercel.app/stureg/forgetpassword/${aadhar}/${newfathername}/${mobno}/${newmailid}`,
                {
                  method: "GET",
                }
              );
        
              const json = await response.json();
             
              if(json.found === true){
                 setRegno(json.res)
                   
                setDuperror(false)
              }else if(json.found === false){
                setIsloading(false)
                setDuperror(true)
             console.log("not found")
              }else{
                setDuperror(true)
              }
        } catch (error) {
            
        }
    }else{
        setDuperror(true)
        setIsloading(false)
    }
    }

  return (
    <div className=" bg-white  border-2 flex flex-col justify-between  drop-shadow-xl rounded-lg px-10 py-2 h-full self-stretch  ">
    <header className="text-center w-full text-2xl text-orange-700 py-2 font-semibold ">
       Forget Registration No    </header>

   {!regno && <> <div className="text-left py-4">
      <p className="text-gray-700 font-semibold  text-lg">Aadhar</p>
      <input
      
      type='number'
     
      onChange={(e) => {
        let value = e.target.value;
        if (value.length > 12) {
          value = value.slice(0, 12); // Ensure it doesn't exceed 12 digits
        }
        setAadhar(value);
      }}
        placeholder="Type Aadhaar No"
        className="input-form-login w-full"
        value={aadhar}
       
      />
    </div>
    <div className="text-left pt-4">
      <p className="text-gray-700 font-semibold  text-lg">Father's Name</p>
      
      <input

        onChange={(e) => {
          setFathername(e.target.value);
        }}
        type="text"
        placeholder="Type Fathername"
        className="input-form-login  w-full"
      />
    </div>
    <div className="text-left pt-4">
      <p className="text-gray-700 font-semibold  text-lg">Mobile No</p>
      
      <input

onChange={(e) => {
    let value = e.target.value;
    if (value.length > 10) {
      value = value.slice(0, 10); // Ensure it doesn't exceed 12 digits
    }
    setMobno(value);
  }}
  value={mobno}
        maxLength={10}
        type="number"
        placeholder="Type Mobile No"
        className="input-form-login  w-full"
      />
    </div>
    <div className="text-left pt-4">
      <p className="text-gray-700 font-semibold  text-lg">Mail Id</p>
      
      <input

        onChange={(e) => {
          setMailid(e.target.value);
        }}
        type="text"
        placeholder="Type Mailid"
        className="input-form-login  w-full"
      />
    </div>
    {duperror && <div className='error-text'>
      Invalid Credentials or All field not filled.<br/> If not registered please Register First.</div>} </>}
    <div className='flex  flex-col   items-center w-full mt-4 justify-evenly'>   
    {!regno  ? <button
      onClick={()=>{checkregistration(); }}
      disabled={isloding}
      className={`text-center w-full  flex items-center  justify-center text-2xl font-bold   border-2 hover:border-2 rounded-full ${isloding ? "py-3": "py-1 hover:bg-slate-100 hover:border-white "} px-4`}
    >
      {isloding ? <img alt="loading" src={Loading} className="w-6 h-6"/> : "Submit"}
    </button>  : <span className='font-semibold text-center'>Your Registration Number is <span className='text-red-500'> {regno}</span></span>  }
    
   
    </div>
    <div className='w-full text-center mb-2'><span className='text-center mx-1'>Back to </span><button onClick={()=>{back()}} className='text-blue-500 cursor-pointer underline underline-offset-2'> Login</button></div>

  </div>
  )
}

export default ForgetReg