import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
function About() {
  const mode = useSelector((state) => state.darkMode);
  const [loaded, setLoaded] = useState(false);

  // destructuring isdarkMode state from mode variable called using useSelector hook of redux
  const { isdarkMode } = mode;
  const handleload = () => {
    setLoaded(true);
  };
  useEffect(() => {
    window.scrollTo({top: 0})
      }, [])
  return (
    <>
      <div className={`${isdarkMode ? "bg-gray-800" : "bg-white"} `}>
        <div className="flex md:flex-row flex-col container mx-auto md:px-14 px-2 mt-10 ">
          <div
            className={`${
              isdarkMode ? "bg-gray-800" : "bg-white"
            } w-full md:px-5 px-5 mb-5`}
          >
            <div className="md:text-xl font-bold text-[#243763] text-opacity-95  text-justify">
            <h1 className="text-gray-900 text-justify font-extrabold border-b-4 text-2xl mb-4">
            हमारे बारे में 
            </h1>
            <h2 className="my-4">ट्रस्ट का नाम - <span className="text-red-400">सनातन संस्कृति विकास ट्रस्ट</span> </h2>

<h2 className="my-4">सर्टिफिकेट नंबर  - <span className="text-red-400">IN-UP19103361292358T</span></h2>
<h2 className="my-4">
ट्रस्ट की स्थापना की तिथि - <span className="text-red-400">29 अक्टूबर 2021</span></h2> 
 </div>
<h1 className="text-gray-900 text-justify font-extrabold border-b-4 text-2xl mb-4">
उद्देश्य
            </h1>
            <p className="md:text-3xl font-semibold text-[#243763] text-opacity-95 text-xl text-justify hindi-text ">
              lukru laL—fr fodkl VªLV ,d lkekftd laxBu gS tks lukru laL—fr ds
              ç'kaldksa dks vkilh vkn'kksZa ij tqVkus&#93; mUgsa lkekftd ,oa
              vkè;kfRed la?k"kksZa ds ekè;e ls çsfjr djus vkSj mudh laL—fr dks
              vkèkqfudrk ds lkFk tksM+us dk dk;Z djrk gSA gekjk eq&#91;; mís';
              lukru laL—fr dh j&#123;kk ç'kalk vkSj fodkl djuk gS&#93; tks gekjh
              çkphurk vkSj vewY; fojklr gSA
              <br />
              <br />
              ge fofHkUu lkaL—frd vkSj èkkfeZd dk;ZØeksa&#93; lEesyuksa vkSj
              laxksf"B;ksa dk vk;kstu djrs gSa&#93; ftuesa yksx viuh laL—fr&#93;
              èkeZ vkSj fopkjèkkjk ds fo"k; esa Kku vkSj vuqHko lktk djrs gSaA
              ge laxBu &#125;kjk lapkfyr èkkfeZd ikB'kkykvksa dk leFkZu djrs
              gSa&#93; tgka cPpksa dks laLdkfjd f'k&#123;kk ,oa vkè;kfRed Kku
              çnku fd;k tkrk gSA
              <br />
              <br />
              ge leqnk; ds yksxksa dks èkkfeZd lkèkukvksa&#93; ifo= iqjk.kksa
              vkSj xzaFkksa ds ekè;e ls voxr djkrs gSa vkSj mUgsa lukru laL—fr
              dh egRoiw.kZrk ij tkx:d djrs gSaA ge lukrfu;ksa dks èkkfeZd vkSj
              vkè;kfRed xq#vksa ds laidZ esa ykrs gSa rkfd os vius èkkfeZd
              lkeF;Z dks fodflr dj ldsaA
            </p>
          </div>
        </div>
        <div className="md:w-[52rem]  w-full rounded-sm mt-10 mx-auto text-center">
          <header className="hindi-text text-3xl font-semibold text-[#243763] my-2 border-b-4">
            vkokgu
          </header>
          <div className="aspect-w-16  aspect-h-9 rounded-md scoll-animation animate">
            <iframe
              className="text-animation animate"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/-GmVZyC-ZcY"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="flex md:flex-row flex-col container mx-auto md:px-14 px-2 mt-10 ">
          <div
            className={`${
              isdarkMode ? "bg-gray-800" : "bg-white"
            } w-full md:px-5 px-5 mb-5`}
          >
            <p className="md:text-2xl font-semibold text-[#243763] text-opacity-95 text-xl text-justify hindi-text ">
              lukru laL—fr fodkl VªLV u dsoy /kkfeZd ,oa lkaL—frd xfrfof/k;ksa
              dks çksRlkfgr djrk gS&#93; cfYd vk/kqfudrk ds lkFk laL—fr dks
              tksM+us ds fy, rduhdh fodkl dk Hkh leFkZu djrk gSA ge uohure
              rduhdh lk/kuksa dk mi;ksx djds v‚uykbu IysVQ‚eksaZ&#93;
              f'k&#123;kk midj.kksa vkSj laxBuksa dk mi;ksx djrs gSa rkfd ge
              larku laL—fr dks vk/kqfud nqfu;k ds lkFk tksM+ ldsaA
              <br />
              <br />
              gekjk y&#123;; lukru laL—fr dks vkxs c&lt;+kus esa la?k"kksaZ dk
              lkeuk djuk gSA ge /kkfeZd ,oa lkaL—frd ewY;ksa ds çfr ladfYir gSa
              vkSj laxfBr leqnk; ds :i esa ge yksxksa ds chp ,drk dks LFkkfir
              djus dk ç;kl djrs gSaA ge ;g ekurs gSa fd lukru laL—fr gekjh vkRek
              dk vk/kkj gS vkSj bls thfor j&#91;kus ds fy, gesa la?k"kZ djuk
              gksxkA
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
