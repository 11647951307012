import React, { useState, useEffect } from 'react'
import logo from "../Photos/Logo.png";
import logo2 from "../Photos/Logo2.png";

import { useDispatch, useSelector } from 'react-redux';
import { handledarkMode, handlepopup } from "../State/action-creators/index";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
function Navbar() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  
  let links = [
    { name: "Home", link: "/" },
    { name: "About", link: "/about" },
    { name: "Team", link: "/team" },
    { name: "Gallary", link: "/gallary" },
    { name: "Contact", link: "/contact" },
  ]
  
  let [open, setOpen] = useState(false);
  const mode = useSelector((state) => state.darkMode);

  // destructuring isdarkMode state from mode variable called using useSelector hook of redux
  const { isdarkMode } = mode;

  const popupmode = useSelector((state) => state.popup);
  const {popupstatus} = popupmode


  useEffect(() => {

    const visitseeder = async ()=>{
      const response = await fetch("https://sanatansvtnew-c5ol.vercel.app/api/visit", {
        method : "POST"
      })
    }
    visitseeder()
  }, [])
  
  // function to be fired on onChange method to switch the mode

  let location = useLocation()
  useEffect(() => {
    // Google Analytics
  }, [location]);

  useEffect(()=>{
    setTimeout(() => {
      dispatch(handlepopup(true))
     }, 2000);
  },[])
  return (
    <nav>
      <div className='bg-[#243763] p-1 flex md:flex-row flex-col justify-between'>
        <div className=' flex font-semibold space-x-2 md:text-sm text-xs py-1 items-center justify-center'>
          <p className='border-r-2 px-2 space-x-2 text-white'><i className="fa-solid fa-phone text-[#FFEBB7] mx-2 "></i>Mob - 7906569269,7088887111</p>
          <a href='https://mail.google.com/mail/u/0/' className='items-center text-white justify-center flex space-x-2'><i className="fa-solid fa-envelope text-[#FFEBB7] mx-2 "></i>Email - sanatansvt@gmail.com </a>
        </div>
        <div className='flex font-semibold space-x-2 md:text-lg text-xl py-1 items-center justify-center mr-2'>
       
          <i className="fa-brands fa-facebook text-[#FFEBB7] mx-2 cursor-pointer"></i>
          <i className="fa-brands fa-instagram text-[#FFEBB7] mx-2  cursor-pointer"></i>
          
        </div>
      </div>
      <header className=' w-full top-0 left-0 '>
        <div className="md:flex md:items-center md:justify-between bg-[#FF6E31]  py-2  ">
          <div className='mx-4 items-center md:justify-center  justify-between  flex md:text-2xl text-xl md:font-semibold right-0'>
            <Link to="/" className='text-black mr-2 text-base overflow flex items-center relative max-w-20 min-w-20 h-16 max-h-16 min-h-16 '>
              <img className='md:w-14 md:h-14 w-20  h-14 cursor-pointer  absolute' src={logo} alt="" />
              <img className='md:w-14 md:h-14 w-20 h-14 cursor-pointer' src={logo2} alt="" />
            </Link>
           
            <p className='text-3xl  whitespace-normal  break-words font-semibold text-white'>सनातन संस्कृति विकास ट्रस्ट</p>
            <div id="toggleButton" onClick={() => { setOpen(!open) }} className='text-4xl text-[#FFEBB7]  md:hidden '>
              <ion-icon name={open ? "close" : "menu"}></ion-icon>
            </div>
          </div>
          

          <div className='md:items-end mx-2 md:align-text-bottom md:bottom-0'>
           

            <ul className={`md:flex md:z-auto z-[-1]  md:items-end obsolute md:align-text-bottom md:mr-5 px-2 py-2  ${open ? "top-20 show opacity-100 dropdown-list  " : '  dropdown-list-back w-0 h-0 md:w-auto md:h-auto  overflow-hidden md:opacity-100 '}`}>

              {/* <div onClick={switchDarkMode} className='flex drop-shadow-sm px-2 items-center justify-center  mr-6 cursor-pointer border-gray-300 border-2 rounded-md'>
                <h1 className='mr-2 text-white'>Mode</h1>
                <ion-icon className="mx-4 text-white bg-white" name={isdarkMode ? "moon-outline" : "sunny-outline"}></ion-icon>
              </div> */}
              {
                links.map((Links) => (
                  <li key={Links.name} className='px-3 md:text-base md:border-0 border-b-2 border-white  md:border-r-[2px]'>
                    <Link onClick={()=>{setOpen(!open)}} className={`md:text-lg hover:text-[#435990] ${location.pathname === Links.link ? "font-semibold text-white" : "font-semibold text-[#243763]"}`} to={Links.link}>{Links.name}</Link>
                  </li>
                ))
              }

            </ul>
          </div>

        </div>

      </header>

    </nav>
  )
}

export default Navbar
