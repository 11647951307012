import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'


const Contact = () => {
    const navigator  = useNavigate();
    useEffect(() => {
        window.scrollTo({top: 0})
          }, [])
    return (
        <> 
           
         <div className='h-screen'>
            <div className='flex flex-col text-center mx-auto items-center  p-10  h-full'>
                <span className="text-2xl mx-auto font-semibold text-center justify-center my-2  ">Address</span>
                <p className='text-xl font-sans font-mediumt ' >76 Kalakunj, Maruti State<br/> Shahganj, Agra, UP 282010</p>
                <span className="text-2xl font-semibold mt-5 my-2">Mobile and Email</span>
                <p>Phone - 7906569269<br/> Email - sanatansvt@gmail.com</p>
                <span className="text-2xl font-semibold mt-5 my-6-">For Donation and Suggestion</span>
                <p>For Donation Please Contact on Whatsapp on above number given<br/> And for Suggestion please email.</p>
                {/* <button onClick={()=>{navigator("/loginadmin")}} className='text-center w-fit text-2xl font-bold hover:bg-green-700 hover:text-white border-2 hover:border-2 hover:border-white rounded-md py-1 px-4'>Admin Login</button>   */}
            </div>
        </div>
      
        </>

    )
}

export default Contact
