import React, { useEffect, useState } from 'react'
import { FaExclamationCircle } from "react-icons/fa";
import { Englishtandc } from './Englishtandc';
import Hinditandc from './Hinditandc';
import { useNavigate } from 'react-router-dom';
const TandC = () => {
  const [isChecked, setIsChecked] = useState(false);
const navigate = useNavigate()
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    window.scrollTo({top: 0})
      }, [])
  const [lang, setLang] = useState("english")
  return (
    <div className='p-2'>
       <div className="md:w-[68rem] mx-auto p-6 my-4 bg-gray-100 rounded-lg shadow-md">
        <div className='flex items-center space-x-4'>
       <button onClick={()=>{setLang("hindi")}} className={`p-2 border px-4 rounded-md  ${lang==="hindi" ? "bg-yellow-100": "bg-white"}`}>Hindi</button>
      <button onClick={()=>{setLang("english")}} className={`p-2 border px-4 rounded-md ${lang==="english" ? "bg-yellow-100": "bg-white"}`}>English</button>
      </div>
      <div className='my-2'>
   {lang=== "english" ? <Englishtandc/>:<Hinditandc/>}
   </div>
   <div className="flex items-center mt-6">
        <input
          type="checkbox"
          id="accept-conditions"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="w-5 h-5 text-blue-600 border-gray-300 rounded"
        />
        <label
          htmlFor="accept-conditions"
          className="ml-2 text-gray-800 cursor-pointer"
        >
          मैं इन सभी शर्तों को स्वीकार करता/करती हूँ। I accept these conditions.
        </label>
      </div>
      {isChecked  && <div className='flex items-center justify-center py-4'>
      <button onClick={()=>{navigate("/regform")}}
                className="text-center w-fit text-2xl font-bold hover:bg-[#243763] hover:text-white border-2 hover:border-2 hover:border-white rounded-full py-1 px-4">Register Now</button>
 </div> }</div>
 </div>
  )
}

export default TandC 