import React, { useEffect } from 'react'

const TermsandCondition = () => {
    useEffect(() => {
        window.scrollTo({top: 0})
          }, [])
  return (
    <div>
         <div className="max-w-3xl mx-auto my-10 px-4 py-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold mb-6">Terms and Conditions</h1>
      <p className="mb-4">
        Welcome to sanatan Sanskriti Vikas Trust. These Terms and Conditions govern your use of our
        website. By registering on our website, you agree to
        comply with these terms. If you do not agree with any part of these terms, please do not register or use our
        website.
      </p>

      <h2 className="text-xl font-semibold mb-2">1. Eligibility</h2>
      <p>
        1.1. Participants must meet the eligibility criteria specified for the registration. Details regarding age,
        educational level, or other requirements will be clearly mentioned in the test announcement.
      </p>
      <p>
        1.2. You must provide accurate and truthful information during registration.
      </p>

      <h2 className="text-xl font-semibold mb-2">2. Registration and Fees</h2>
      <p>
        2.1. A registration fee stated on the website is required for successfull registration.
      </p>
      <p>
        2.2. The fee must be paid online through the payment gateway provided on our website.
      </p>

      <h2 className="text-xl font-semibold mb-2">3. Test Rules</h2>
      <p>
        3.1. Participants must follow the rules and regulations specified for the test, which will be
        provided in the test guidelines.
      </p>
      <p>
        3.2. Any form of cheating, plagiarism, or dishonesty will result in disqualification and forfeiture of the
        registration fee.
      </p>

      <h2 className="text-xl font-semibold mb-2">4. Intellectual Property</h2>
      <p>
        4.1. All content on our website, including but not limited to text, graphics, and test materials, is the
        property of Sanatan Sanskriti Vikas Trust and is protected by copyright laws.
      </p>
      <p>
        4.2. You may not use, copy, or distribute our content without written permission from us.
      </p>

      <h2 className="text-xl font-semibold mb-2">5. Liability</h2>
      <p>
        5.1. We are not responsible for any technical issues or errors that may affect the registration process or the
        conduct of the  test.
      </p>
      <p>
        5.2. We are not liable for any damages or losses resulting from your participation in the  test.
      </p>

      <h2 className="text-xl font-semibold mb-2">6. Modification of Terms</h2>
      <p>
        6.1. We reserve the right to modify these Terms and Conditions at any time. Any changes will be posted on this
        page, and your continued use of our website indicates your acceptance of the updated terms.
      </p>

      <h2 className="text-xl font-semibold mb-2">7. Governing Law</h2>
      <p>
        7.1. These Terms and Conditions are governed by the laws of India, and any disputes arising from them will be
        subject to the exclusive jurisdiction of the courts located in Agra, Uttar Pradesh.
      </p>
    </div>
    </div>
  )
}

export default TermsandCondition