import React, { useEffect } from "react";
import { IoPerson } from "react-icons/io5";
const Team = () => {
  useEffect(() => {
    window.scrollTo({top: 0})
      }, [])
  

  return (
    <div className="bg-slate-50 py-4 ">
    <div className="max-w-4xl mx-auto  p-6   rounded-md  ">
    <h2 className="text-3xl font-semibold mb-6 bg-gray-100  text-orange-500  p-2 rounded-md drop-shadow-md">Organizational Team</h2>
    <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
      <thead className="bg-gray-200">
        <tr>
          <th className="py-3 px-5 text-left text-gray-700">Name</th>
          <th className="py-3 px-5 text-left text-gray-700">Role</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="py-3 px-5 border-b">श्री थान सिंह</td>
          <td className="py-3 px-5 border-b">अध्यक्ष</td>
        </tr>
        <tr>
          <td className="py-3 px-5 border-b">श्री राम बृक्ष भदौरिया</td>
          <td className="py-3 px-5 border-b">उपाध्यक्ष</td>
        </tr>
        <tr>
          <td className="py-3 px-5 border-b">श्री मनोज कुमार अग्रवाल</td>
          <td className="py-3 px-5 border-b">कोषाध्यक्ष</td>
        </tr>
        <tr>
          <td className="py-3 px-5 border-b">श्री विक्रम सिंह 
राना</td>
          <td className="py-3 px-5 border-b">सचिव</td>
        </tr>
        <tr>
          <td className="py-3 px-5 border-b">श्री सुधीर कुमार गुप्ता</td>
          <td className="py-3 px-5 border-b">कार्यालय प्रबन्धक</td>
        </tr>
        <tr>
          <td className="py-3 px-5 border-b">श्री नरेन्द्र कुमार यादव</td>
          <td className="py-3 px-5 border-b">कार्यक्रम प्रभारी</td>
        </tr>
        <tr>
          <td className="py-3 px-5 border-b">श्री बीकेश कुमार</td>
          <td className="py-3 px-5 border-b">प्रभारी तकनीकी, प्रचार एवं प्रसार</td>
        </tr>
        <tr>
          <td className="py-3 px-5 border-b">श्री मोहन सिंह चाहर</td>
          <td className="py-3 px-5 border-b">संगठन मन्त्री</td>
        </tr>
        <tr>
          <td className="py-3 px-5 border-b">श्री अशोक कुमार शर्मा</td>
          <td className="py-3 px-5 border-b">कानूनी सलाहकार</td>
        </tr>
        <tr>
          <td className="py-3 px-5 border-b">श्री धर्मवीर सिंह</td>
          <td className="py-3 px-5 border-b">रणनीतिक एवं कार्यक्रम सलाहकार</td>
        </tr>
        <tr>
          <td className="py-3 px-5">श्री धीरज सारस्वत</td>
          <td className="py-3 px-5">धर्म प्रचारक एवं प्रभारी धार्मिक कार्यक्रम</td>
        </tr>
      </tbody>
    </table>
  </div></div>
  );
};

export default Team;
