import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PricingRefuncPolicy = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      {" "}
      <div className="max-w-3xl mx-auto my-10 px-4 py-6 bg-white shadow-md rounded-lg">
        <h1 className="text-2xl font-bold mb-4">
          Pricing, Refunds and Cancellations Policy
        </h1>
        <p className="mb-4">
          This Refunds and Cancellations Policy outlines the circumstances under
          which refunds or cancellations are allowed.
        </p>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">1. Cancellations</h2>
          <p>
            1.1. You can not cancel your application in any circumstances once
            you have submitted the registration form and paid the fee.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">2. Refunds</h2>
          <p>
            2.1. No refunds will be made if you have successfully registered and
            Paid the registration fee.
          </p>
          <p>
            2.2. Refunds will be made only when you have paid the fee from your
            side but it is not showing in the Registration Dashboard, but for
            that you have to{" "}
            <span
              className="text-blue-600 underline cursor-pointer underline-offset-1"
              onClick={() => {
                navigate("/contact");
              }}
            >
              contact us
            </span>
            .
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">3. No-Shows</h2>
          <p>
            3.1. If you fail to attend the scholarship test, you will not be
            eligible for a refund.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">
            4. Test Cancellation by Us
          </h2>
          <p>
            4.1. In the event that we need to cancel the scholarship test for
            any reason, we will notify you, and provide half refund of the
            registration fee , but for that you have to <span className='text-blue-600 underline cursor-pointer underline-offset-1' onClick={()=>{navigate("/contact")}}>contact us</span>.
          </p>
          <p>
            4.2. We are not responsible for any other expenses or losses
            incurred due to test cancellation.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">5. Pricing Policy</h2>
          <p>
            5.1. The registration fee for the scholarship test is mentioned in
            home page.
          </p>
          <p>
            5.2. We do not charge additional fees or costs related to the
            scholarship test, except where explicitly stated.
          </p>
          <p>
            5.3. We reserve the right to change the registration fee in the
            future. Any price changes will be announced in advance and will not
            affect participants who have already registered.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingRefuncPolicy;
