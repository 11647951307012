import React, { useEffect, useRef, useState } from "react";
import main from "../Photos/maha.jpg";
import logo from "../Photos/Logo.png";
import line from "../Photos/line.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Photos/loading.gif";
import Bookfront from "../Photos/Bookfront.jpg";
import Stulogin from "./StudentReg/Stulogin";
import { handlepopup } from "../State/action-creators";
import { IoClose } from "react-icons/io5";
import newlogo from "../Photos/new.gif"
import ssvt from "../Photos/ssvt.jpg"
import { FaRegHandPointRight } from "react-icons/fa";
export default function Landingpage(props) {

  const dispatch = useDispatch();
  const [loginstatus,setLoginstatus] = useState(false);
  const navigate = useNavigate()
  const mode = useSelector((state) => state.darkMode);
  const { isdarkMode } = mode;
  const [isloding, setIsloading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(5);
  const timerRef = useRef(null);

  useEffect(() => {
    window.scrollTo({top: 0})
      }, [])
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleScroll = () => {
      const animatedElements = document.querySelectorAll(".scroll-animation");
      const textanimation = document.querySelectorAll(".text-animation");
      animatedElements.forEach((element) => {
        const elementPosition = element.getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;

        if (elementPosition < viewportHeight) {
          element.classList.add("animate");
          // Remove scroll event listener
        }
      });
      textanimation.forEach((element) => {
        const elementPosition = element.getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;

        if (elementPosition < viewportHeight) {
          element.classList.add("");
          // Remove scroll event listener
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  let [open, setOpen] = useState(false);
  const popupmode = useSelector((state) => state.popup);
  const {popupstatus} = popupmode
useEffect(()=>{ const Username =  localStorage.getItem("notowatch");
const  passw=  localStorage.getItem("basicrtn");

 if(Username && passw){
   setLoginstatus(true)
   console.log(loginstatus)
 }else{
   setLoginstatus(false)
 }},[])
  return (
    <div className="items-center  py-2 justify-center  bg-slate-50 overflow-hidden">
      <div     className='p-4'   style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'all 0.3s ease-in-out',
          transform: popupstatus ? 'translateY(0)' : 'translateY(-100px)',
          opacity: popupstatus ? 1 : 0,
          pointerEvents: popupstatus ? 'auto' : 'none', // Optional, for user interactions
          zIndex: 1000,
        }}><div style={{height: window.innerHeight-20, width: window.innerWidth-10}} className=' overflow-y-scroll  '>     <div className='cursor-pointer text-white p-2  border-2 bg-blue-600  top-0 hover:bg-yellow-100 hover:text-green-500 rounded-md rounded-b-none w-fit' onClick={()=>{dispatch(handlepopup(false))}}><IoClose size={32}/></div>     <div className='bg-blue-600  px-8 relative py-8 border md:w-[54rem]  md:mx-auto drop-shadow-xl flex rounded-md  flex-col space-y-4 '>
          <img src={ssvt} alt="ssvtphto"/> <div className='flex items-center justify-center' ><img alt="gif" src={newlogo}/> <p onClick={()=>{navigate("/tandc"); dispatch(handlepopup(false))}} className='  cursor-pointer  animate-pulse font-bold underline text-slate-50'>छात्रवृत्ति के लिए पंजीकरण के लिए यहाँ क्लिक करें।</p></div>
      </div></div></div>
      <div
        className={`flex flex-col md:flex-row  justify-between items-stretch bg-slate-50   md:space-x-2      p-4 md:p-2 `}
      >
        <div className="h-full w-full drop-shadow-md    pl-4 pr-2 pt-2 text-[#243763] font-semibold rounded-md ">
          <h1 className="text-2xl font-bold  ring ring-[#FFEBB7] text-[#FFEBB7]  rounded-md  p-2 bg-[#FF6E31] text-center mb-6">
            छात्रवृति परीक्षा 2024
          </h1>
          <p className="text-base text-justify  leading-loose mb-4">
            सनातन संस्कृति ज्ञान छात्रवृति परीक्षा का आयोजन छात्रों को आधुनिक
            शिक्षा के साथ-साथ सनातन संस्कृति के प्रति जागरूक करने तथा उसमें रुचि
            उत्पन्न करने के लिए किया जा रहा है । इस परीक्षा में भाग लेने के लिए 100 रुपये का शुल्क जमा करना होगा । इस परीक्षा में आवेदन करने वाले
            समस्त प्रतिभागियों को बालाजी इंस्टीट्यूट ऑफ कम्प्यूटर एजुकेशन
            शास्त्रीपुरम, आगरा पर उपलब्ध सभी कम्प्यूटर कोर्सेस में 10% की छूट
            शिक्षण शुल्क में दी जाएगी । इसके अतिरिक्त निम्न पुरुस्कार प्रदान किए
            जाएंगे
          </p>
          <div className="text-[#243763] font-semibold ">
            <h2 className="text-2xl font-bold  ring ring-[#FFEBB7] text-[#FFEBB7]  rounded-md  p-2 bg-[#FF6E31] text-center mb-6">
              पुरस्कार:
            </h2>
            <ul className="list-disc pl-6 text-base text-justify space-y-2">
              <li>
                <strong>प्रथम पुरुस्कार:</strong> 10000 रु० तक का मोबाइल फोन
                अथवा 10000 रु० तक का कम्प्यूटर कोर्स।
              </li>
              <li>
                <strong>द्वितीय पुरस्कार:</strong> 5000 रु० तक की साइकिल अथवा
                5000 रु० तक का कम्प्यूटर कोर्स।
              </li>
              <li>
                <strong>तृतीय पुरुस्कार:</strong> 2500 रु० तक का हैड फोन अथवा
                2500 रु० का कम्प्यूटर कोर्स।
              </li>
            </ul>
            <p className="mt-4 text-base text-justify">
              <strong>विशेष पुरुस्कार:</strong> परीक्षा में 60% से अधिक प्राप्त
              करने वाले छात्रों को संस्थान में उपलब्ध कम्प्यूटर कोर्स के शुल्क
              में 20% छूट दी जाएगी।
            </p>
          </div>
        </div>

        <div className="md:pr-2 pt-2 md:w-[80rem]">
          <div className="px-10 drop-shadow-lg flex justify-between flex-col   py-2 rounded-md bg-white h-full border-[#243763] border-2">
            <span className="font-serif text-lg font-semibold">सूचनाएं</span>
            <ul className="list-disc space-y-3">
              <li>
                <div className="">
                  <p className="font-medium">
                    छात्रवृत्ति परीक्षा के लिए महत्वपूर्ण तिथियाँ
                  </p>
                  <span className="mt-4 text-lg font-semibold text-green-500">
                    पंजीकरण
                  </span>
                  <div className="grid font-medium grid-cols-2">
                    <div className="flex  justify-between">
                      <span>आरम्भ तिथि </span>
                      <span>-</span>
                    </div>
                    <p className="text-start pl-8">परीक्षा रद्द कर दी गयी है </p>
                    <div className="flex justify-between">
                      <span>अंतिम तिथि </span>
                      <span>-</span>
                    </div>
                    <p className="text-start pl-8"> परीक्षा रद्द कर दी गयी है </p>
                    <div className="flex justify-between">
                      <span> शुल्क </span>
                      <span>-</span>
                    </div>
                    <p className="text-start pl-8">100 रुपये</p>
                    <div className="flex justify-between">
                      <span> आयु सीमा </span>
                      <span>-</span>
                    </div>
                    <p className="text-start pl-8">01/01/1999  से <br/> 31/12 /2012  तक </p>
                  </div>
                  <span className="mt-2 text-lg font-semibold text-green-500">
                    परीक्षा{" "}
                  </span>
                  <div className="grid font-medium grid-cols-2">
                    <div className="flex  justify-between">
                      <span>तिथि</span>
                      <span>-</span>
                    </div>
                    <p className="text-start pl-8">शीग्र घोषित की जाएगी</p>
                    <div className="flex justify-between">
                      <span>माध्यम</span>
                      <span>-</span>
                    </div>
                    <p className="text-start pl-8">ऑनलाइन</p>
                    <div className="flex justify-between">
                      <span> परिणाम </span>
                      <span>-</span>
                    </div>
                    <p className="text-start pl-8"></p>
                  </div>
                </div>
              </li>
              <div  onClick={()=>{navigate("/syllabus")}} className="text-center flex justify-center items-center space-x-4 cursor-pointer animate-bounce underline underline-offset-2 font-bold text-blue-700 "> पाठ्यक्रम जानने के लिए यहाँ क्लिक करें <div className="mx-4"><FaRegHandPointRight/></div> </div>
              <li className="font-medium">
                <p className="text-red-500 font-semibold">! नोट</p>
                <p className="py-2">
                  पंजीकरण की अंतिम तिथि संभावित है और आवश्यकता के अनुसार
                  समायोजित की जा सकती है।

                </p>
                <p className="py-2">
                  यदि परीक्षा की तिथि बदलती है, तो यह केवल वेबसाइट पर प्रदर्शित
                  की जाएगी।{" "}
                </p>
              </li>
            </ul>
            <p className='text-red-500 font-bold text-center justify-center'>Please click register for filling the form.</p>
          </div>
        </div>

        <div className=" pt-2 self-stretch md:max-w-[30rem] h-[36rem] md:h-auto relative flex overflow-hidden w-full md:pr-2">

          {!loginstatus ? <Stulogin />: <div className="flex   flex-col border-[#243763]  py-4 rounded-md space-y-2 border-2 justify-center items-center h-full w-full bg-white"><div className="text-green-500 font-bold">Already Logged In</div><button onClick={()=>{navigate("/registeredstudent?login=true&first=false")}} className="py-2 px-4 border bg-white rounded-md hover:bg-green-500 font-semibold  drop-shadow-md border-[#243763] hover:text-white">Open Dashboard</button></div>}
        </div>
      </div>
      <section className="mt-10 items-center text-center justify-center    content-center md:mx-60 mx-4">
        <section>
          <figure className="flex items-center justify-center">
            <img src={line} className="w-[30rem]" alt="line for division" />
          </figure>
          <header>
            <h2 className="hindi-text text-[#243763] md:text-4xl text-3xl  font-bold opacity-90  my-2  text-center">
              lukru
            </h2>
          </header>

          <p className=" text-[#243763] bold scroll-animation  md:text-2xl text-xl tracking-normal md:font-semibold   leading-7 md:leading-relaxed text-justify">
            सनातन धर्म, जिसे हिंदू धर्म के नाम से भी जाना जाता है, भारतीय
            उपमहाद्वीप की सबसे प्राचीन धार्मिक परंपरा है। इस धर्म को सनातन कहा
            जाता है क्योंकि इसे किसी एक स्थापित या नियमबद्ध संहिता से नहीं बांधा
            गया है, बल्कि इसे मान्यता के विभिन्न रूपों से जोड़ा गया है। सनातन
            धर्म की सुरक्षा अत्यंत महत्वपूर्ण है। यह हमारी प्राचीन और मौलिक
            संस्कृति, विचारधारा, और धार्मिकता का प्रतीक है। यह धर्म हमें अनंत
            ज्ञान, धार्मिक अनुभव, नैतिकता, और सामाजिक सामंजस्य की शिक्षा देता
            है। सनातन धर्म के मूल तत्व जीवन को सार्वभौमिक विकास और उन्नति की
            दिशा में प्रेरित करते हैं। हमें इसे समझने, संरक्षित करने, और इसके
            मूल्यों को अपने जीवन में शामिल करने की आवश्यकता है।
          </p>
          <figure className="flex items-center justify-center">
            <img src={line} className="w-[30rem]" alt="line for division" />
          </figure>
          <header>
            <h2 className="hindi-text text-[#FF6E31] md:text-4xl text-3xl  font-bold opacity-90  my-2  text-center">
              ,d iz;kl
            </h2>
          </header>

          <p className=" text-[#FF6E31] bold scroll-animation  md:text-2xl text-2xl tracking-normal md:font-semibold   leading-7 md:leading-relaxed text-justify">
            सनातन सांस्कृतिक विकास ट्रस्ट का उद्देश्य पूरी तरह से सनातन की
            सुरक्षा और विकास के लिए है। यह एक प्रयास है जो सनातन समुदाय के लोगों
            को उनके धर्म के प्रति जागरूक करता है। उन्हें यह ज्ञात कराता है कि
            केवल भौतिक संपदा ही सब कुछ नहीं है, बल्कि समाज के आध्यात्मिक और
            सांस्कृतिक मूल्यों को समझना और उनका संरक्षण करना भी आवश्यक है। यह
            संगठन शिक्षा, संस्कृति, और आध्यात्मिकता के क्षेत्र में जागरूकता
            फैलाने का काम करता है और धार्मिक पुस्तकों के संग्रह, सांस्कृतिक
            कार्यक्रमों का आयोजन करता है। इसके माध्यम से सनातन धर्म के
            महत्वपूर्ण अनुयायियों को संगठित और सक्रिय बनाए रखने की कोशिश की जाती
            है ताकि हम अपने धर्म के मूल तत्वों को लागू कर सकें और धर्म के साथ एक
            सुसंगत जीवन जी सकें।
          </p>
          <figure className="flex items-center justify-center">
            <img src={line} className="w-[30rem]" alt="line for division" />
          </figure>

          <header>
            <h2 className="hindi-text text-[#243763] md:text-4xl text-3xl  font-bold opacity-90  my-2  text-center">
              la?k"kZ
            </h2>
          </header>

          <p className=" text-[#243763] bold scroll-animation  md:text-2xl text-2xl tracking-normal md:font-semibold   leading-7 md:leading-relaxed text-justify">
            सनातन संस्कृति विकास ट्रस्ट के यह उद्देश्य मात्र उद्देश्य ही रह
            जायेंगे यदि सनातनियों का साथ आर्थिक, सामाजिक और मानसिक रूप से न मिले
            तो । कार्य जैसे सांस्कृतिक समारोह, पुस्तक वितरण, निशुल्क शिक्षा व
            प्रतिष्ठानों के निर्माण के लिए नियमित रूप से सनातनियों की आर्थिक
            सहभागिता आवश्यक है। अतः ट्रस्ट के लिए यह एक संघर्ष की तरह है। आर्थिक
            सहयोग के साथ ही सनातनियों अपने बच्चों को संस्कारिक एवं धार्मिक
            मूल्यों के साथ पालना, उन्हें पवित्र पुराणों, ग्रंथों और संस्कृति की
            महत्त्वपूर्ण जानकारी प्रदान करनी चाहिए। जो कि आज के युग में सबसे
            महत्वपूर्ण कार्य है।
          </p>
          <figure className="flex items-center justify-center">
            <img src={line} className="w-[30rem]" alt="line for division" />
          </figure>
        </section>
      </section>
      <div className="md:my-10 md:mx-60 md:flex flex-col cursor-pointer md:flex-row my-2 md:space-y-0 space-y-4 mx-4 md:justify-around ">
        <div className=" border-2  transition-box border-gray-100   py-10 pb-14  px-8  justify-center align-center text-center text-[#FF6E31] rounded-lg items-center">
          <i className="fa-regular fa-user fa-4x mb-5 text-[#FF6E31] "></i>
          <p className="mb-5 text-xl  font-semibold">
            हमारा साथ देने के लिए <br /> हमारे सहयोगी बनें ।
          </p>
          <Link
            to="/contact"
            className="py-2 px-10 mt-5 text-base font-semibold border-2 rounded-full border-[#FF6E31] hover:border-none bg-[#FF6E31]
            text-white"
          >
            सहयोगी बनें
          </Link>
        </div>
        <div className=" border-2  transition-box border-gray-100  cursor-pointer py-10 pb-14  px-8  justify-center align-center text-center text-[#FF6E31] rounded-lg items-center">
          <i className="fa-solid fa-indian-rupee-sign fa-4x mb-5 text-[#FF6E31] "></i>
          <p className="mb-5 text-xl  font-semibold">
            हमारा साथ देने के लिए <br /> हमारा सहयोग दें।
          </p>
          <Link
            to="/contact"
            className="py-2 px-10 mt-5 text-base font-semibold border-2 rounded-full border-[#FF6E31] hover:border-none bg-[#FF6E31]
            text-white"
          >
            सहयोग दें
          </Link>
        </div>
        <div className=" border-2  transition-box border-gray-100 cursor-pointer  py-10 pb-14  px-8  justify-center align-center text-center text-[#FF6E31]  rounded-lg items-center">
          <i className="fa-regular fa-comments fa-4x mb-5 text-[#FF6E31] "></i>
          <p className="mb-5 text-xl  font-semibold">
            हमारा साथ देने के लिए <br /> हमें सुझाव दें।
          </p>
          <Link
            to="/contact"
            className="py-2 px-10 mt-5 text-base font-semibold border-2 rounded-full border-[#FF6E31] hover:border-none bg-[#FF6E31]
                 text-white"
          >
            सुझाव दें
          </Link>
        </div>
      </div>
      <figure className="flex items-center justify-center">
        <img src={line} className="w-[30rem]" alt="line for division" />
      </figure>
    </div>
  );
}
