import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import logo2 from "../../Photos/logo512.png";
import Loading from "../../Photos/loading.gif";
import ReactToPrint from 'react-to-print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const cashfree = window.Cashfree({
  mode: "production", //or production
});

const RegistrationDash = () => {
  const divToPrintRef = useRef(null);
  const downloadPDF = () => {
    const input = document.getElementById('pdf-content');
    
    html2canvas(input)
    .then((canvas) => {
      const jpegData = canvas.toDataURL('image/jpeg');
      const a = document.createElement('a');
      a.href = jpegData;
      a.download = 'downloaded-image.jpeg';
      a.click();
    });
  };

  useEffect(() => {
    const handlePrint = (event) => {
      if (event.ctrlKey && event.key === 'p') {
        event.preventDefault();
        printDivContent();
      }
    };

    window.addEventListener('keydown', handlePrint);

    return () => {
      window.removeEventListener('keydown', handlePrint);
    };
  }, []);

  const printDivContent = () => {
    const content = divToPrintRef.current.innerHTML;
    const originalBody = document.body.innerHTML;
    document.body.innerHTML = content;
    window.print();
    document.body.innerHTML = originalBody;
    divToPrintRef.current.innerHTML = content
  };
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const location = useLocation();
  const decerror = "Please Check the Declaration Then Proceed"
  const [photo, setPhoto] = useState(null);
  const [data, setData] = useState(null);
  const [isloding, setIsloading] = useState(true);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // Parse query parameters from the URL
  const query = new URLSearchParams(location.search);
  // Get 'search' parameter

  const logincheck = query.get("login");
  const checkfirst = query.get("first");

  const checklogin = async (Username, passw) => {
   

    
    try {
      const response = await fetch(
        `https://sanatansvtnew-c5ol.vercel.app/stureg/getdata/checklogin/${logincheck}/${Username}/${passw}`,
        {
          method: "GET",
        }
      );

      const json = await response.json();

      if (json.found === true) {
        setData(json.res[0]);

        let imageUrl = null;
        if (json.res[0].image) {
          const blob = new Blob([new Uint8Array(json.res[0].image.data)], {
            type: "image/*",
          }); // create a Blob object from binary data
          imageUrl = URL.createObjectURL(blob);
        }
        setPhoto(imageUrl);
        setIsloading(false);
      } else if (json.found === false) {
        console.log("not found");
      }
    } catch (error) {}
   
  };
  useEffect(() => {
    const Username = localStorage.getItem("notowatch");
    const passw = localStorage.getItem("basicrtn");

    if (Username && passw) {
      checklogin(Username, passw);
    } else {
      navigate("/");
    }
  }, []);
  
  const amount = 100
 
  const paymentcreator = async () => {
    setIsloading(true);
    const {
      data: { order },
    } = await axios.post(
      `https://sanatansvtnew-c5ol.vercel.app/paymentinfo/createorder/${data.regNo}`,{cname: data.name, cmail: data.mailid,cphone: `${data.mobno}`, amount}
    );

    

    let checkoutOptions = {
      paymentSessionId: order.payment_session_id,
      redirectTarget: "_modal",
    };
    setIsloading(false);
    const result = await cashfree.checkout(checkoutOptions);
    setIsloading(true)
    if (result.error) {


      const {
        status,
      } = await axios.post(
        `https://sanatansvtnew-c5ol.vercel.app/paymentinfo/verifypayment/${data.regNo}`
      );

      window.location.reload()
    }
    if (result.redirect) {
      // This will be true when the payment redirection page couldnt be opened in the same window
      // This is an exceptional case only when the page is opened inside an inAppBrowser
      // In this case the customer will be redirected to return url once payment is completed
      const {
        status,
      } = await axios.post(
        `https://sanatansvtnew-c5ol.vercel.app/paymentinfo/verifypayment/${data.regNo}`
      );

      window.location.reload()
    }
    if (result.paymentDetails) {

   
      const {
        status,
      } = await axios.post(
        `https://sanatansvtnew-c5ol.vercel.app/paymentinfo/verifypayment/${data.regNo}`
      );
      window.location.reload()

    }
 
  };
  const componentRef = useRef();
  const datefunction = (item) => {
    const date = new Date(item);
    const string = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    return string;
  };
  const timefunction = (item) => {
    const date = new Date(item);
    const string = `${date.getHours()} :  ${date.getMinutes()} : ${date.getSeconds()}`;
    return string;
  };
  const logout = () => {
    localStorage.removeItem("notowatch");
    localStorage.removeItem("basicrtn");
    navigate("/");
  };
  return (
    <>
      {isloding && (
        <div className="h-screen w-screen fixed inset-0 z-[2000] overflow-hidden flex justify-center items-center  bg-white">
          <img alt="loading" src={Loading} className="w-20 h-20" />
        </div>
      )}<div className="h-screen p-4 my-auto bg-slate-50 ">
        <div className="md:w-[52rem] mx-auto flex space-x-2 justify-between my-2">
         <button
                onClick={() => {
                  logout();
                }}
                className="border py-2 px-4 bg-white rounded-md hover:bg-red-500 drop-shadow-md  hover:text-white  font-semibold"
              >
                Log Out
              </button>
              <div className="flex space-x-2">
              <ReactToPrint
        trigger={() => <button
          className="border py-2 px-4 rounded-md hover:bg-red-500 bg-white hover:text-white w-28 drop-shadow-md  font-semibold"
        >
          Print
        </button>}
        content={() => componentRef.current}
      />
           <button
                onClick={() => {
                  downloadPDF()
                }}
                className="border py-2 px-4 bg-white rounded-md hover:bg-red-500 w-28 hover:text-white hover:border-white drop-shadow-md font-semibold"
              >
                Download
              </button>
      </div>
      </div>
      <div ref={componentRef}  id='pdf-content'  className="bg-white md:w-[52rem] md:mx-auto rounded-md p-2 drop-shadow-md">
        {data && photo ? (
          <>
            {" "}
            <div >
             
              
              <div  className="text-2xl flex border-b-2  flex-col justify-center py-2">
                <span className=" w-full text-center py-4   font-bold">
                  Registration No :{" "}
                  <span className="text-green-500  text-center">
                    &nbsp;{data.regNo}
                  </span>{" "}
                </span>
                {checkfirst === "true" && (
                  <p className="text-red-500 bg-yellow-200 rounded-md p-2 text-center">
                    (Important : Please Note this Registration Number.)
                  </p>
                )}
              </div>
              <div className="flex md:justify-between items-center py-2  ">
                <div>
                  <p className="text-xl font-bold">{data.name}</p>
                  <p className=" font-semibold">C/O {data.fatherName}</p>
                  <p>
                    Registration Status :{" "}
                    {data.regStatus.status === "Fee Received" ? (
                      <span className="text-green-500">
                        {" "}
                        Succesfully Registered
                      </span>
                    ) : (
                      <span className="text-red-500 ">Fees Not Submitted</span>
                    )}
                  </p>
                </div>
                <div className="p-2 border-2 ">
                  <img alt="stu" src={photo} width="100" height="100" />
                </div>
              </div>
            </div>
            <div></div>
          </>
        ) : (
          <div></div>
        )}
        {data && data.regStatus.status !== "Fee Received" ? (
          <div className="flex flex-col items-center w-full my-4 justify-evenly">
            <div className="flex items-center mt-6">
              <input
                type="checkbox"
                id="accept-conditions"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="w-5 h-5 text-blue-600 border-gray-300 rounded"
              />
              
              <label
                htmlFor="accept-conditions"
                className="ml-2 text-gray-800 cursor-pointer"
              >
                I have successfully read the Terms and Condition, Pricing and
                Refund/Cancellation Policy.
              </label>
            </div>
            {!isChecked && <p className="text-red-500 font-semibold py-2 ">{decerror}</p>}
            <button
              onClick={() => {
                if (isChecked) {
                  paymentcreator();
                }
              }}
              className="text-center cursor-pointer w-fit text-2xl font-bold hover:bg-green-700 hover:text-white border-2 hover:border-2 hover:border-white rounded-md py-1 px-4"
            >
              Pay Fee 100Rs.
            </button>
          </div>
        ) : (
          <div className=" bg-white md:w-[52rem] md:mx-auto rounded-md p-2 drop-shadow-md my-2">
            {data && data.regStatus.status === "Fee Received" && (
              <div className="grid md:grid-cols-4 items-center gap-2">
                <span className="self-center md:text-center">
                  <p className="text-lg font-semibold">Reference Id</p>
                  {data.regStatus.payment_id}
                </span>
                <span className="text-green-500 self-center md:text-center">
                  <p className="text-lg font-semibold text-black">Amount</p>
                  {data.regStatus.payreceived}
                </span>
                <span className="self-center md:text-center">
                  <p className="text-lg font-semibold">Date</p>
                  {datefunction(data.regStatus.transactionDate)}
                </span>
                <span className="self-center md:text-center">
                  <p className="text-lg font-semibold">Time</p>
                  {timefunction(data.regStatus.transactionDate)}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      </div>
    </>
  );
};

export default RegistrationDash;
