import { POPUP_TOGGLE } from "../action-creators/types";

// initial state
const initialState = {
  popupstatus : false
};


const popuptoggler = (state = initialState, action) => {
  switch (action.type) {
    case POPUP_TOGGLE:
      return {
        ...state,
        // getting value from the action file and changing isdarkMode State.
        popupstatus: action.payload,
      };
    default:
      return state;
  }
};

export default popuptoggler;