
//import { useState } from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Landingpage from './Components/Landingpage';
import Navbar from "./Components/Navbar";
import About from "./Components/About"
import Institute from './Components/Institute';
import Footer from './Components/Footer';
import Team2 from './Components/Team2'
import Gallary from './Components/Gallary';
import Contact from './Components/Contact';
import RegForm from './Components/StudentReg/Form';
import Stulogin from './Components/StudentReg/Stulogin';
import RegistrationDash from './Components/StudentReg/RegistrationDash';
import TandC from './Components/StudentReg/TandC';
import TermsandCondition from './Components/TermsandCondition';
import Policies from './Components/Policies';
import PricingRefuncPolicy from './Components/PricingRefuncPolicy';
import ScholarshipTestSyllabus from './Components/StudentReg/ScholarshipTestSyllabus';
import Scholaradmin from './Components/StudentReg/Scholaradmin';

function App() {

  return (
    <>
    <Navbar/>
    
    <Routes>
          <Route path="/" element={<Landingpage/>}/>
          <Route path="/about" element={<About/>} />
          <Route path="/institute" element={<Institute/>} />
          <Route path="/team" element={<Team2/>} />
          <Route path="/gallary" element={<Gallary/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/regform" element={<RegForm/>} />
          {/* <Route path="/scholaradmin" element={<Scholaradmin/>} /> */}
          <Route path="/studentlogin" element={<Stulogin/>} />
          <Route path="/registeredstudent" element={<RegistrationDash/>} />
          <Route path="/tandc" element={<TandC/>} />
          <Route path="/termsandcondition" element={<TermsandCondition/>} />
          <Route path="/privacypol" element={<Policies/>} />
          <Route path="/pricingrefundandcancellation" element={<PricingRefuncPolicy/>} />
          <Route path="/syllabus" element={<ScholarshipTestSyllabus/>} />
          <Route path="/scholaradmin" element={<Scholaradmin/>} />
          

    </Routes>
    <Footer/>
    </>
  );
}

export default App;
