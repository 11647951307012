import React, { useEffect, useState } from 'react'
import { indianStates, statecitydata } from './citydata'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import "./forms.css"
import * as Yup from 'yup';
import Preview from './Preview';
import { format } from 'date-fns';
const initialValues = {
    state: '', district: '',
    address:'',
    city:'',
    pincode:'',
    name: '',
    mobno: '',
    mailid: '',
    gender: '',
    dob: '',
    fatherName: '',
    motherName: '',
    aadhar: '',
    qualification: '',
    adtype:'',
    image: ''
  };
  const minDate = new Date('1999-01-01');
const maxDate = new Date('2012-12-31');
const emailValidationRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

const validationSchema = Yup.object({
    state: Yup.string().required('Please select a state'),
    city: Yup.string().required('Please type City or Village Name'),
    district: Yup.string().required('Please select District Name'),
    address: Yup.string().required('Please Type your Address'),
    image: Yup.mixed().required('An image is required').test('fileFormat', 'Unsupported format', (value) => {
      const allowedFormats = ['image/jpeg', 'image/png'];
      return value && allowedFormats.includes(value.type);
    }),
    pincode: Yup.string().required('Please Type pincode').length(6, 'Pin Code must be exactly 6 digits').matches(/^[1-9]\d+$/, 'Pin code must contain only digits and should not start with 0'),
    name: Yup.string().required('Name is required'),
    mobno: Yup.string().required('Mobile number is required').length(10, 'Mobile number must be exactly 10 digits').matches(/^[1-9]\d+$/, 'Mobile number must contain only digits  and should not start with 0'),
    mailid: Yup.string().email('Invalid email address').matches(emailValidationRegex, 'Invalid email address'),
    gender: Yup.string().required('Gender is required'),
    dob: Yup.date().required('Date of birth is required').min(minDate, `Date must be on or after ${format(minDate, 'MMMM d, yyyy')}`)
    .max(maxDate, `Date must be on or before ${format(maxDate, 'MMMM d, yyyy')}`),
    fatherName: Yup.string().required("Father's name is required"),
    adtype: Yup.string().required("This is required"),
    motherName: Yup.string().required("Mother's name is required"),
    qualification: Yup.string().required('Qualification is required'),
    aadhar: Yup.string().required('Aadhaar number is required').matches(/^[1-9]\d{11}$/, 'Aadhaar must be 12 digits and cannot start with zero'),
  });
  // const exampleForm = {
  //   aadhar: 342342342342, // Numeric value
  //   address: "HW 234 Shastripuram, Agra", // Corrected text format
  //   adtype: "Friends", // Properly quoted
  //   city: "Dehtora",
  //   district: "Agra",
  //   dob: "2012-04-09", // Correct date format
  //   fatherName: "Rahit Kumar", // Fixed typo
  //   gender: "Male",
  //   image: {
  //     name: "Untitled design.png",
  //     lastModified: 1713507879125,
  //     lastModifiedDate: "2024-04-19T06:24:39.000Z", // ISO 8601 formatted date
  //     webkitRelativePath: "",
  //     size: 2542913,
  //     type: "image/png", // Added MIME type
  //   }, // Corrected nested object format
  //   mailid: "shd@gmail.com",
  //   mobno: 3403980933, // Numeric value
  //   motherName: "Sunita Devi",
  //   name: "Shridhar",
  //   pincode: 2092300, // Numeric value
  //   qualification: "12th",
  //   state: "Uttar Pradesh",
  // };

  
  
const RegForm = () => {
    const [cityOptions, setCityOptions] = useState([]);
    const [preview, setPreview] = useState(null);
    const [formvalues, setFormvalue] =useState(null);
    const [formprevew, setFormpreview] = useState(false);
    useEffect(() => {
window.scrollTo({top: 0})
    }, [])
  return (<>
  {formvalues && formprevew && <div className='fixed h-screen w-screen inset-0  backdrop-blur-sm py-6 z-[1100] flex items-center justify-center'><Preview toggleof={()=>setFormpreview(false)} imgprev={preview} values={formvalues}/></div>}
    <div className='bg-slate-50 py-4'><div className='z-[1000]  drop-shadow-lg bg-slate-100 p-4 md:w-[68rem] md:mx-auto'>

        <div className='text-center py-2 mb-4 bg-white font-bold text-xl rounded-md '>
            <p>Scholarship Registration Form</p>
            <p className='text-base font-semibold text-orange-500'>For Sanatan Sanskriti Vikas trust</p>
        </div>
        <p className='mb-4  text-gray-800'>For any problem in form filling please contact on <span className='text-red-600'>+91 8532938381</span></p>
        <span className='mb-4 text-red-500'>* All Fields are Required.</span>
        <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setFormvalue(values)
        setFormpreview(true)
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form><div className='headings '>Personal Details </div>
  
          <div className='maingrid '>
          
          <div className="field">
            <label className="text-label" htmlFor="name">
              Name
            </label>
            <Field
              name="name"
              type="text"
              className="input-form"
              placeholder="Type Full Name"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          {/* Mobile number field */}
          <div className="field">
            <label className="text-label" htmlFor="mobno">
              Mobile Number<span className='text-green-500'>(Whatsapp should be available on this number)</span>
            </label>
            <Field
              name="mobno"
              type="text"
              className="input-form"
              maxLength={10}
              placeholder="Type Mobile No"
            />
            <ErrorMessage
              name="mobno"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          {/* Email field */}
          <div className="field">
            <label className="text-label" htmlFor="mailid">
              Email
            </label>
            <Field
              name="mailid"
              type="email"
              className="input-form"
              placeholder="Type Email"
            />
            <ErrorMessage
              name="mailid"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          {/* Gender field */}
          <div className="field">
            <label className="text-label" htmlFor="gender">
              Gender
            </label>
            <Field
              name="gender"
              as="select"
              className="input-form"
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </Field>
            <ErrorMessage
              name="gender"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          {/* Date of Birth field */}
          <div className="field-select">
            <label className="text-label font-semibold" htmlFor="dob">
              Date of Birth(Month/Date/Year)
            </label>
            <Field
              name="dob"
              type="date"
              className="input-form"
              
            />
            <ErrorMessage
              name="dob"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          {/* Father's Name field */}
          <div className="field">
            <label className="text-label" htmlFor="fatherName">
              Father's Name
            </label>
            <Field
              name="fatherName"
              type="text"
              className="input-form"
              placeholder="Type Father's name"
            />
            <ErrorMessage
              name="fatherName"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          {/* Mother's Name field */}
          <div className="field">
            <label className="text-label" htmlFor="motherName">
              Mother's Name
            </label>
            <Field
              name="motherName"
              type="text"
              className="input-form"
              placeholder="Type Mother's name"
            />
            <ErrorMessage
              name="motherName"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          {/* Aadhar field */}
          <div className="field">
            <label className="text-label" htmlFor="aadhar">
              Aadhaar Number
            </label>
            <Field
              name="aadhar"
              type="text"
              className="input-form"
              placeholder="Type Aadhar Number"
              maxLength={12}
            />
            <ErrorMessage
              name="aadhar"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          </div>
          <div className='headings '>Address Details </div>
        
          <div className='maingrid'>
             {/* Aadress field */}
          <div className="field">
            <label className="text-label" htmlFor="address">
              Address
            </label>
            <Field
              name="address"
              type="text"
              className="input-form"
              placeholder="Type your address"
            />
            <ErrorMessage
              name="address"
              component="div"
              className="text-red-500 text-sm"
            />
          </div> {/* Pincode field */}
          <div className="field">
            <label className="text-label" htmlFor="pincode">
              Pincode
            </label>
            <Field
              name="pincode"
              type="text"
              className="input-form"
              placeholder="Type Pincode"
              maxLength={6}
            />
            <ErrorMessage
              name="pincode"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className='field-select'>
            <label className='text-label font-semibold' htmlFor="state">State</label>
            <Field
              as="select"
              name="state"
              id="state"
              className="input-form"
              onChange={(e) => {
                const state = e.target.value;
                setFieldValue('state', state); // Update Formik value
                setCityOptions(statecitydata[state] || []); // Update city options based on selected state
                setFieldValue('district', ''); // Reset city selection
              }}
            >
              <option className=""  value="">Select a state</option>
              {indianStates.map((state) => (
                <option  className="" key={state} value={state}>
                  {state}
                </option>
              ))}
            </Field>
            <ErrorMessage name="state" component="div" className="error-text" />
          </div>

          <div className='field-select'>
            <label className='text-label font-semibold' htmlFor="district">District</label>
            <Field
              as="select"
              name="district"
              id="district"
              className="input-form"
              disabled={cityOptions.length === 0} // Disable if no city options
            >
              <option className="input-form"  value="">Select a District</option>
              {cityOptions.map((city) => (
                <option  className="input-form"  key={city} value={city}>
                  {city}
                </option>
              ))}
            </Field>
            <ErrorMessage name="district" component="div" className="error-text" />
          </div>
             {/* Aadress field */}
             <div className="field">
            <label className="text-label" htmlFor="city">
              City/Village
            </label>
            <Field
              name="city"
              type="text"
              className="input-form"
              placeholder="Type your City or Village Name"
            />
            <ErrorMessage
              name="city"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          </div>
          <div className='headings '>Other Information </div>
          <div className='maingrid'>

 {/* Qualification field */}
 <div className="field-select">
            <label className="text-label font-semibold" htmlFor="qualification">
              Highest Qualification
            </label>
            <Field
              name="qualification"
              as="select"
              className="input-form"
            >
              <option className='' value="">Select Qualification</option>
              <option value="upto8">Up to 8th</option>
              <option value="10th">High School</option>
              <option value="12th">Intermediate</option>
              <option value="Graduation">Graduation or Above</option>
            </Field>
            <ErrorMessage
              name="qualification"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
           {/* Adtype field */}
          <div className="field-select">
            <label className="text-label whitespace-normal font-semibold " htmlFor="adtype">
              How do you get to know about this Scholarship?
            </label>
            <Field
              name="adtype"
              as="select"
              className="input-form"
            >
              <option className='' value="">Select Type</option>
              <option value="Pemplate">Pemplate</option>
              <option value="Social Media">Social Media</option>
              <option value="Friends">Friends</option>
              <option value="Localad">Local Advertisement(Banners, Wall Paint etc.)</option>
              <option value="Other">Other</option>

            
            </Field>
            <ErrorMessage
              name="adtype"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          </div>
          <div className='headings '>Photo </div>
          <div className='maingrid'>
        
          <div className='field'>
            <label className='text-label' htmlFor="image">Upload Latesh Photo:</label>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={(event) => {
                const file = event.target.files[0];
                setFieldValue('image', file);
                // For previewing the image
                if (file) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    setPreview(e.target.result);
                  };
                  reader.readAsDataURL(file);
                }
              }}
            />
             <ErrorMessage
              name="image"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          {/* Display preview if available */}
          {preview && (
            <div className='field'>
              <label className='text-label'>Preview:</label>
              <img src={preview} alt="Preview" width="150" height="150" />
            </div>
          )}
          </div>
          
          <div className='flex justify-end'>
            <button className='p-2 px-4  rounded-full bg-green-600 text-white hover:bg-green-700  hover:text-white' type="submit" disabled={isSubmitting}>
              Preview
            </button>
          </div>
        
        </Form>
      )}
    </Formik>
    </div>
    </div>
    </>
  )
}

export default RegForm