import React, { useEffect, useState } from 'react'
import { FiRefreshCcw } from "react-icons/fi";
const Scholaradmin = () => {
    const [password, setPassword] = useState("");
    const [Username, setUsername] = useState("");
    const [login, setlogin] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("Credentials are Wrong");
    const [saverr, setSaveErr] = useState(false);
    const [data,setData]=useState(null);
    const getdata =async ()=>{
        if (login === true) {
        try {
            const response = await fetch("https://sanatansvtnew-c5ol.vercel.app/stureg/getdata/scholarshiponlynew", {
                method: "GET",
              });
              const json = await response.json();
              console.log(json)
              setData(json);
        } catch (error) {
                console.log("fetch error",error.message)
        }}  else if (login === false) {
            setData(null);
          } 
    }
    useEffect(()=>{
        getdata()
    },[login])
      //function for login
  const loginphone = async () => {
    console.log(Username, password)
try {
  const data = {
    username : Username,
    password : password
  }
  const response = await fetch("https://sanatansvtnew-c5ol.vercel.app/verify",{ 
    method: "POST",
    body: JSON.stringify(data),
    headers: {'Content-Type': "application/json"}
  })

  const json = await response.json();
  console.log(json)
  if(json.verified){
    console.log("am i here")
    setlogin(true)
    setData(json.data);
  }else{
    setError(true)
  }
  
} catch (error) {
  setError(true)
}
  };
  return (
    <div className="bg-slate-50 p-4 ">
        {!login ? (
          <div className="mx-auto h-screen  my-1 px-2 py-2 w-fit items-center">
            <div className="border-4 rounded-lg px-10 py-10 w-full md:w-[32rem] space-y-6 my-4">
              <header className="text-center w-full text-2xl font-semibold text-gray-900">
                Admin Login
              </header>
              <div className="text-left">
                <span className="text-xl">UserName</span>
                <input
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  placeholder="Type Username"
                  className="w-full  border-2   py-2 px-2"
                />
              </div>
              <div className="text-left">
                <span className="text-xl">Password</span>
                <input
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type="password"
                  placeholder="Type Username"
                  className="w-full  border-2  py-2 px-2"
                />
              </div>
              <div className={`${error ? "visible" : "invisible hidden"}`}>
                {message}
              </div>
              <button
                onClick={loginphone}
                className="text-center w-fit text-2xl font-bold hover:bg-green-700 hover:text-white border-2 hover:border-2 hover:border-white rounded-md py-1 px-4"
              >
                Login
              </button>
            </div>
          </div>
        ) : (
    <div className='p-4 mx-auto w-[68rem] rounded-md drop-shadow-md bg-white'>
        <div className='text-lg font-semibold my-4 '>Scholarship Data</div>
        <div onClick={()=>{loginphone()}} className='font-semibold flex space-x-1 cursor-pointer items-center'><FiRefreshCcw/> <div> Refresh</div></div>
        <div className='grid items-start gap-2 grid-cols-9 border p-4 rounded-md bg-green-300 font-semibold'>
            <span>Reg No</span>
            <span>Name</span>
            <span>Father Name</span>
            <span>Dob</span>
            <span>Mobile No</span>
            <span>Aadhar No</span>
            <span>Address</span>
            <span>Photo</span>
            <span>Fee Status</span>
        </div>
        {data && data.length>0 && data.map((item,index)=>{let imageUrl=null
        const date = new Date(item.dob)
        let dateofbirth = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()} `
                    if(item.image){
                      const blob = new Blob([new Uint8Array(item.image.data)], { type: "image/*" }); // create a Blob object from binary data
                      imageUrl = URL.createObjectURL(blob);
                    }; return (<div key={index} className='grid items-center gap-4 justify-start p-4 border-2 my-2 rounded-md  grid-cols-9'>
            <span className=''>{item.regNo}</span>
            <span className=''>{item.name}</span>
            <span className=''>{item.fatherName}</span>
            <span className=''>{dateofbirth}</span>
            <span className=''>{item.mobno}</span>
            <span className=''>{item.aadhar}</span>
            <span className=''>{item.address[0].address}, {item.address[0].city}, {item.address[0].district}, {item.address[0].pincode}, {item.address[0].state}</span>
            <img alt="student" src={imageUrl} width="100" height="100"/>
            <span className=''>{item.regStatus.status}</span>
            </div>)})}
        <div></div>
    </div>)}
    </div>
    )
}

export default Scholaradmin