import React from 'react'
import { FaExclamationCircle } from "react-icons/fa";
export const Englishtandc = () => {
  return (
    <div><div>
    <h2 className="text-2xl font-bold text-center mb-4">Instructions & Conditions</h2>

    <div className="mb-4 flex items-center">
      <FaExclamationCircle className="text-red-500 mr-2" />
      <span className="text-sm text-red-600 font-semibold">
        Note: Before applying, please carefully read all instructions and conditions.
      </span>
    </div>

    <ul className="list-disc pl-6 space-y-2">
      <li>
        **Ensure all information is accurate**. Incorrect or false information will result in
        disqualification from both the application and prizes.
      </li>
      <li>
        **The exam form must be filled out in English only**.
      </li>
      <li>
        **Only one registration is allowed per Aadhaar number**. All registered students will receive a
        10% discount on computer course fees.
      </li>
      <li>
        **The exam will be conducted online**. It can be taken on a computer, mobile, or tablet.
        The date and time will be provided on the Trust's website.
      </li>
      <li>
        The exam link will be given on your profile by entering your registration number and password.
      </li>
      <li>
        **The exam syllabus**: 40% based on Sanatan Dharma, 20% General Mathematics, 20% General Knowledge,
        and 20% Computer Knowledge.
      </li>
      <li>
        The exam will consist of 100 multiple-choice questions to be solved within 90 minutes.
      </li>
      <li>
        **The exam will only be conducted if there are at least 300 registrations**. Otherwise, 50% of the
        application fee will be refunded.
      </li>
      <li>
        **The exam will be organized by the Sanatan Sanskriti Vikas Trust**.
      </li>
      <li>
        Successful students in the examination will be admitted to the Balaji Institute of Computer
        Education, Dehtora, Shastripuram, Sikandra, Agra, for the computer course.
      </li>
      <li>
        **In case of a tie**, a lucky draw will be conducted at the location determined by the Sanatan
        Sanskriti Vikas Trust.
      </li>
      <li>
        **In negative marking**, 1 mark will be given for correct answers, and 1/4 mark will be deducted for
        wrong answers.
      </li>
      <li>
        **Inappropriate means or assistance from others during the exam will lead to cancellation of the
        application**.
      </li>
      <li>
        **If a question is found to be erroneous, all candidates will receive equal marks for that
        question**.
      </li>
      <li>
        **The Sanatan Sanskriti Vikas Trust reserves all rights regarding registration and exam-related
        changes or cancellations**.
      </li>
      <li>
        The examination will be conducted in both Hindi and English.
      </li>
      <li>
        **For any exam-related issues, call 8532938381**.
      </li>
    </ul>
    </div></div>
    
  )
}
