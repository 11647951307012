import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import "./forms.css"
import Loading from "../../Photos/loading.gif"
import ForgetReg from './ForgetReg';
const Stulogin = () => {
  const [isloding,setIsloading] =useState(false);
    const [password, setPassword] = useState(0);
    const [Username, setUsername] = useState(0);
    const [login, setlogin] = useState(true);
    const [error, setError] = useState(false);
    const [duperror,setDuperror]=useState(false);
    const [message, setMessage] = useState("Credentials are Wrong");
    const [forget, setForget] = useState(false);
    const navigate = useNavigate();
    const checklogin = async ()=>{
        try {
            const response = await fetch(
                `https://sanatansvtnew-c5ol.vercel.app/stureg/getdata/checklogin/false/${Username}/${password}`,
                {
                  method: "GET",
                }
              );
        
              const json = await response.json();
             
              if(json.found === true){
                const params = new URLSearchParams({
                    login: true,
                    first:false
                  });
                  localStorage.setItem("notowatch",Username)
                  localStorage.setItem("basicrtn",password)
              
                  navigate(`/registeredstudent?${params.toString()}`); 
                setDuperror(false)
              }else if(json.found === false){
                setIsloading(false)
                setDuperror(true)
             console.log("not found")
              }else{
                setDuperror(true)
              }
        } catch (error) {
            
        }
    }
    const back = ()=>{
      setForget(false)
    }
  return (<>
  
            <div style={{transition: 'all 0.5s ease-in-out',transform: forget ? "scale(-1)": "scale(1)",opacity: forget ? 0 : 1, zIndex : forget ? 899 : 901}} className=" bg-white border-2 z-[1999]  top-2 bottom-0 left-0 right-0 flex absolute flex-col justify-between  drop-shadow-xl rounded-lg px-10 py-2 h-full self-stretch  ">
              <header className="text-center w-full text-2xl text-orange-700 py-2 font-semibold ">
                 Welcome to Santan Sanskriti Viaks Trust
              </header>
              <header className="text-center w-full text-xl border-b-2 border-orange-700 font-semibold ">
                 Student Login
              </header>
              <div className="text-left py-4">
                <p className="text-gray-700 font-semibold  text-lg">Registration No</p>
                <input
                type='number'
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  placeholder="Type Regno"
                  className="input-form-login w-full"
                />
              </div>
              <div className="text-left pt-4">
                <p className="text-gray-700 font-semibold  text-lg">Password</p>
                <p className="text-sm text-red-500">DOB Year= 2003, Aadhaar Last Four Digits = 2233, Password = "20032233"</p>
                <input

                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type="password"
                  placeholder="Type Password"
                  className="input-form-login  w-full"
                />
              </div>
               <button onClick={()=>{setForget(true)}} className='text-gray-700  cursor-pointer text-right'>Forgot Registration No?</button>
              {duperror && <div className='error-text'>
                Invalid Credentials.<br/> If not registered please Register First.</div>}
              <div className='flex  flex-col   items-center w-full mt-4 justify-evenly'>   
              <button
                onClick={()=>{checklogin(); setIsloading(true)}}
                disabled={isloding}
                className={`text-center w-full  flex items-center  justify-center text-2xl font-bold   border-2 hover:border-2 rounded-full ${isloding ? "py-3": "py-2 hover:bg-slate-100 hover:border-white "} px-4`}
              >
                {isloding ? <img alt="loading" src={Loading} className="w-8 h-8"/> : "Login"}
              </button>    
              
             
              </div>
 
            </div>
          <div style={{transition: 'all 0.5s ease-in-out',transform: forget ? "scale(1)": "scale(-1)",opacity: forget ? 1 : 0}} className='absolute z-[900] top-2 left-0 right-0 bottom-0'>

            <ForgetReg back={back}/>
          </div>

                  </>
  )
}

export default Stulogin