import React from 'react'
import { Context,useState } from 'react';
import Loading from './Loading';
const Gallary =  () => {
  const [loaded,setLoaded]=useState(false)
  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
 
  const images = importAll(require.context('../Photos/Gallary', false, /\.(png|jpe?g|svg)$/));
 useState(async ()=>{
  const top = await require('../Photos/Gallary/6.jpg')
  setTimeout(() => {
    setLoaded(true)  
  },200);
  
 },[])


  return (
    <>
    {loaded ? (<div className='px-4 mt-4 '>
    <header className='border-b-2 border-t-2'>
        <time className="font-bold text-md text-[#243763] md:text-lg"> 05/06/2023</time>
        <p className=' font-semibold text-2xl text-gray-900'>शास्त्रीपुरम C - 1 में वृक्षारोपण </p>
      </header>
      <section className="container mx-auto md:grid md:grid-cols-3 gap-2">
      <img src={images['15.jpeg']}   onClick={() => {
    window.open(images['15.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      <img src={images['16.jpeg']}   onClick={() => {
    window.open(images['16.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      <img src={images['17.jpeg']}   onClick={() => {
    window.open(images['17.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      <img src={images['18.jpeg']}   onClick={() => {
    window.open(images['18.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      <img src={images['23.jpeg']}   onClick={() => {
    window.open(images['23.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      </section> 
      <header className='border-b-2 border-t-2'>
        <time className="font-bold text-md text-[#243763] md:text-lg"> 03/06/2023</time>
        <p className=' font-semibold text-3xl text-gray-900'> बैंक ऑफ़ इण्डिया के शाखा प्रबंधक जी को विदाई सम्मान देते हुए </p>
      </header>
      <section className="container mx-auto md:grid md:grid-cols-3 gap-4">
      <img src={images['14.jpg']}   onClick={() => {
    window.open(images['14.jpg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>

      </section>
      <header className='border-b-2 border-t-2'>
        <time className="font-bold text-md text-[#243763] md:text-lg"> 03/06/2023</time>
        <p className=' font-semibold text-3xl text-gray-900'>UPPSC 2022 की परीक्षा में कुमारी प्रतिभा लोहिया के चयनित होने पर स्वागत एवं सम्मान करते हुए</p>
      </header>
      <section className="container mx-auto md:grid md:grid-cols-4 gap-2">
      <img src={images['19.jpeg']}   onClick={() => {
    window.open(images['19.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      <img src={images['21.jpeg']}   onClick={() => {
    window.open(images['21.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      <img src={images['20.jpeg']}    onClick={() => {
    window.open(images['20.jpeg'], '_blank'); // Opens image in a new tab
  }}alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      <img src={images['22.jpeg']}   onClick={() => {
    window.open(images['22.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      <img src={images['24.jpeg']}   onClick={() => {
    window.open(images['24.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      </section>
      <header className='border-b-2 border-t-2'>
        <time className="font-bold text-md text-[#243763] md:text-lg"> 29/01/2023</time>
        <p className='hindi-text font-semibold text-4xl text-gray-900'>lukru laLd`fr VªLV &#125;kjk vk;ksftr Nk=ofRRk ifj&#123;kk ds fy, iqjLdkj forj.k</p>
      </header>
      <section className="container mx-auto md:grid md:grid-cols-3 gap-4">
      <img src={images['10.jpg']}  onClick={() => {
    window.open(images['10.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      <img src={images['11.jpg']}   onClick={() => {
    window.open(images['15.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      <img src={images['12.jpg']}   onClick={() => {
    window.open(images['15.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      </section>
      
      <header className='border-b-2 border-t-2'>
        <time className="font-bold text-md text-[#243763] md:text-lg"> 05/06/2022</time>
        <p className='hindi-text font-semibold text-4xl text-gray-900'>i;kZoj.k fnol ds miy&#123;k esa ikS/kkjksi.k</p>
      </header>
      <section className="container mx-auto md:grid md:grid-cols-3 gap-4">
      <img src={images['9.jpeg']}   onClick={() => {
    window.open(images['9.jpg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      <img src={images['3.jpeg']}   onClick={() => {
    window.open(images['3.jpg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      </section>

      <header className='border-b-2 border-t-2'>
        <time className="font-bold text-md text-[#243763] md:text-lg"> 05/05/2022</time>
        <p className='hindi-text font-semibold text-3xl text-gray-900'> सनातन संस्कृति ज्ञान पुस्तिका का विमोचन एवं वितरण </p>
      </header>
      <section className="container mx-auto md:grid md:grid-cols-3 gap-4">
      

      <img src={images['4.jpeg']}   onClick={() => {
    window.open(images['4.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
      <img src={images['5.jpeg']}   onClick={() => {
    window.open(images['5.jpeg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>

      </section>

      
      <header className='border-b-2 border-t-2'>
        <time className="font-bold text-md text-[#243763] md:text-lg"> 05/03/2022</time>
        <p className='hindi-text font-semibold text-4xl text-gray-900'>Hkkxor esa lEeku</p>
      </header>
      <section className="container mx-auto md:grid md:grid-cols-3 gap-4">
      <img src={images['6.jpg']}   onClick={() => {
    window.open(images['6.jpg'], '_blank'); // Opens image in a new tab
  }} alt="mih" className='m-2 rounded-md drop-shadow-sm'/>
     
      </section> 


     
    </div>) : (<div className='h-screen flex items-center text-center justify-center'><Loading/></div>)}
    </>
  )
}

export default Gallary
