import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router-dom";
import Loading from "../../Photos/loading.gif";

const Preview = ({ values, imgprev, toggleof }) => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate()
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    };

  const [realvalues, setRealvalues] = useState(null);
  const [duperror, setDuperror] = useState(null);
  const [isloding,setIsloading] =useState(false);


  const options = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  const compression = async () => {
    // Convert Base64 string into a Blob
    const base64ToBlob = (base64String) => {
      const base64 = base64String.split(",")[1]; // Remove the Data URL prefix
      const binary = atob(base64); // Convert Base64 to binary
      const bytes = new Uint8Array(binary.length);

      for (let i = 0; i < binary.length; i++) {
        bytes[i] = binary.charCodeAt(i); // Fill the byte array
      }

      // Create a Blob with the correct MIME type
      return new Blob([bytes], { type: "image/jpeg" });
    };

    const blob = base64ToBlob(imgprev); // Read the file as an array buffer to get the BLOB

    const compressedFile = await imageCompression(blob, options);
    return compressedFile;
  };
  const navigatetologin = async (Username,password)=>{

    try {
        const response = await fetch(
            `https://sanatansvtnew-c5ol.vercel.app/stureg/getdata/checklogin/false/${Username}/${password}`,
            {
              method: "GET",
            }
          );
    
          const json = await response.json();
   
          if(json.found === true){
            const params = new URLSearchParams({
                login: true,
                first: true
              });
              setIsloading(false)
              localStorage.setItem("notowatch",Username)
              localStorage.setItem("basicrtn",password)
              navigate(`/registeredstudent?${params.toString()}`); 
            
          }else if(json.found === false){
         console.log("not found")
          }
    } catch (error) {
        
    }
}
  const checkandsumbit= async ()=>{
    setIsloading(true)
    if(!isChecked){
      setIsloading(false)
      setDuperror("Please check the Declaration.")
    }else{
      setDuperror(null)
    try {
        const response = await fetch(
            `https://sanatansvtnew-c5ol.vercel.app/stureg/getdata/checkreg/${Number(values.aadhar)}`,
            {
              method: "GET",
            }
          );
    
          const json = await response.json();

          if(json.found === true){
            setIsloading(false)
            setDuperror("Aadhar Already Registered Please Contact For Registration No")
          }else if(json.found === false){
            onSubmit()
          }
    } catch (error) {
        console.log({error : error.message})
    }}
  }
  // Use an async function to handle asynchronous calls
  const onSubmit = async () => {

    const compressed = await compression();
 // Wait for the compression to complete

    const formdata = new FormData();
    let formvalue = {...values};

    const propertiesToDelete = [
      "image",
      "address",
      "city",
      "district",
      "pincode",
      "state",
      "mobno",
      "aadhar"
    ];

    // Loop over the array and delete each property
    propertiesToDelete.forEach((prop) => {
      delete formvalue[prop];
    });

    formvalue.mobno =Number(values.mobno)
    formvalue.aadhar =Number(values.aadhar)
    
    formvalue.address = {
       address:values.address,
      city: values.city,
      district: values.district,
      state: values.state,
      pincode: Number(values.pincode),
    };

    formdata.append("data", JSON.stringify(formvalue));
    formdata.append("image", compressed);
      const response = await fetch(
        `https://sanatansvtnew-c5ol.vercel.app/stureg/savedata`,
        {
          method: "POST",
          body: formdata,
        }
      );

      const json = await response.json();
      if(json && json.name){
        const Username = json.regNo
        const dobdate = new Date(json.dob)
        const dobyear = dobdate.getFullYear() 
        const numberStr = json.aadhar.toString()
        const aadhardigits = numberStr.slice(-4);
        const password = `${dobyear}${aadhardigits}`
        
        navigatetologin(Username,password)
      }

    // Now you can access the resolved value
  };
  return (
    <>   
    {isloding &&<div className="h-screen w-screen fixed inset-0 overflow-hidden flex justify-center items-center  bg-white">
     <img alt="loading" src={Loading} className="w-20 h-20"/>
      </div> }<div className="bg-white   p-2 pb-10  w-full h-full overflow-y-scroll mx-3 border-2 border-black md:w-[68rem] my-8 rounded-md">
      <div className="headings ">Personal Details </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 ">
        <div className="field">
          <label className="text-label">Name</label>
          <span>{values.name}</span>
        </div>
        <div className="field">
          <label className="text-label">Father's Name</label>
          <span>{values.fatherName}</span>
        </div>
        <div className="field">
          <label className="text-label">Mother's Name</label>
          <span>{values.motherName}</span>
        </div>
        <div className="field">
          <label className="text-label">Date of Birth</label>
          <span>{values.dob}</span>
        </div>
        <div className="field">
          <label className="text-label">Gender</label>
          <span>{values.gender}</span>
        </div>
        <div className="field">
          <label className="text-label">Mobile No</label>
          <span>{values.mobno}</span>
        </div>
        <div className="field">
          <label className="text-label">Email</label>
          <span>{values.mailid}</span>
        </div>
      </div>
      <div className="headings ">Adress </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 ">
        <div className="field">
          <label className="text-label">Address</label>
          <span>{values.address}</span>
        </div>
        <div className="field">
          <label className="text-label">State</label>
          <span>{values.state}</span>
        </div>
        <div className="field">
          <label className="text-label">District</label>
          <span>{values.district}</span>
        </div>
        <div className="field">
          <label className="text-label">City/Village</label>
          <span>{values.city}</span>
        </div>
        <div className="field">
          <label className="text-label">Email</label>
          <span>{values.mailid}</span>
        </div>
      </div>
      <div className="headings ">Other Details </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 ">
        <div className="field">
          <label className="text-label">Qualification</label>
          <span>{values.qualification}</span>
        </div>
        <div className="field">
          <label className="text-label">
            How do you get to know about this Scholarship?
          </label>
          <span>{values.adtype}</span>
        </div>
      </div>
      <div className="headings ">Photo</div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 ">
        <div className="field">
          <label className="text-label"></label>
          <img src={imgprev} alt="Preview" width="150" height="150" />
        </div>{" "}
      </div>
  
      <div className="headings ">Declaration</div>
      <div className=" flex items-center space-x-4  my-2 ">
   
        <input
          type="checkbox"
          id="accept-conditions"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="w-5 h-5 text-blue-600 border-gray-300 rounded"
        />
      <div>
        <label
          htmlFor="accept-conditions"
          className="ml-2 block text-gray-800 cursor-pointer"
        >
           I confirm that the information provided in this form is accurate and true to the best of my knowledge.  I accept responsibility for any consequences arising from providing false or misleading information.
        </label>
        <label
          htmlFor="accept-conditions"
          className="ml-2 block text-gray-800 cursor-pointer"
        >
           मैं पुष्टि करता/करती हूँ कि इस फॉर्म में दी गई जानकारी मेरे ज्ञान के अनुसार सटीक और सत्य है।  मैं झूठी या भ्रामक जानकारी प्रदान करने से उत्पन्न होने वाले किसी भी परिणाम के लिए जिम्मेदारी स्वीकार करता/करती हूँ।
        </label>
        </div>
      </div>
      <div className="flex justify-end items-center space-x-2">
      {duperror && <div className="flex justify-end">
          <span className=" text-sm text-red-500  mt-1">{duperror}</span>
        </div>}
        <div className="flex justify-end">
          <button
            onClick={() => {
              toggleof();
            }}
            className="p-2 px-4  rounded-full bg-gray-600 text-white hover:bg-gray-700  hover:text-white"
            type="submit"
          >
            Edit
          </button>
        </div>
        <div></div>
        <div className="flex  space-x-4 justify-end">
         
          <button
            onClick={() => checkandsumbit()}
            className="p-2 px-4  rounded-full bg-green-600 text-white hover:bg-green-700  hover:text-white"
          >
            Submit
          </button>
        </div>
      </div>
    </div></>

  );
};

export default Preview;
